import React, {useEffect, useState} from 'react'
import api from '../../api/api'

import {Snackbar} from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import {Link, navigate} from 'gatsby'
// import payment1 from '../../assets/images/payment/payment1.png'
import payment2 from '../../assets/images/payment/payment2.png'
// import payment3 from '../../assets/images/payment/payment3.png'
import payment4 from '../../assets/images/payment/payment4.png'
import payment5 from '../../assets/images/payment/payment5.png'
import {de, es} from 'date-fns/locale';
import {getSeats} from '../../api/service'
const {format} = require("date-fns");
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const CartContent = ({services, discounts}) => {
    const [error, setError] = useState(null)
    const [cart, setCart] = useState(null)
    const [bizum, setBizum] = useState(false)

    const [paymentMethod, setPaymentMethod] = useState('card')
    const [total, setTotal] = useState(null)
    const [related, setRelated] = useState([])
    const [showRelated, setShowRelated] = useState(false)
    const [ordering, setOrdering] = useState(false)
    const [familiars, setFamiliars] = useState([]);
    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })
    useEffect(() => {
        api.post(`${process.env.API_URL}/familiars`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {

                setFamiliars(response.data.familiars);


            })
            .catch((error) => {

                switch (error.response.status) {
                    case 401: // Not logged in
                    case 419: // Session expired
                        localStorage.removeItem("user");
                        window.dispatchEvent(new Event("removeUser"));
                        break;

                    default:

                }
            });

        var setting_bizum = JSON.parse(localStorage.getItem('settings')).filter(item => item.key === 'bizum.status')
        if (setting_bizum.length > 0) {
            setBizum(setting_bizum[0].value === "true")
        }
        setTotal(0)
        if (localStorage.getItem('cart') !== null) {
            setCart(JSON.parse(localStorage.getItem('cart')))
        } else {
            setCart([])
        }

        window.addEventListener('storage', () => {
            setCart(JSON.parse(localStorage.getItem('cart')))
        })
        var cart = JSON.parse(localStorage.getItem("cart"));

        if (cart && cart.length > 0) {
            var price = 0;
            var relatedServices = []
            for (const [key, item] of Object.entries(cart)) {
                var service = services.filter(obj => {return obj.id === item.service_id})[0]
                if (service !== undefined) {
                    relatedServices[item.service_id] = []

                } else {
                    cart.splice(key, 1);
                    localStorage.setItem('cart', JSON.stringify(cart));
                    setCart(cart)
                }

            }
            for (const [key, item] of Object.entries(cart)) {
                try {
                    var service = services.filter(obj => {return obj.id === item.service_id})[0]
                    var itemprice = service.price;
                    service.related.forEach(function (relation) {
                        if (parseInt(relation.id) !== parseInt(service.id) && relatedServices[relation.id] === undefined) {
                            var data = []
                            data['name'] = relation.name
                            data['slug'] = relation.slug
                            relatedServices[relation.id] = data
                            setShowRelated(true)
                        }
                    })
                    var group_discount = discounts.filter(obj => {return parseInt(obj.group_id) === parseInt(service.group_id)})[0]
                    if (item.discount !== undefined) {
                        itemprice = service.price - (service.price * item.discount_amount / 100)

                    }
                    if (group_discount !== undefined) {
                        itemprice = itemprice.toFixed(2) - (itemprice * group_discount.percentage / 100)
                    }
                    if (item.complement !== '') {
                        itemprice = itemprice + item.complement_amount;
                    }
                    price = price + (itemprice * item.units)

                } catch (error) {

                }


            }
            setTotal(parseFloat(price).toFixed(2))
        }
        setRelated(relatedServices)
    }, [])

    const handleClose = () => {
        setError(null)
    }
    const handleChange = (event) => {
        setPaymentMethod(event.target.value)
    };
    const renderSubTotal = (item, service, discount, group_discount) => {

        var price = 0;
        if (item.discount !== '') {
            price = service.price - (service.price * item.discount_amount / 100)
        } else {
            price = service.price;
        }
        if (group_discount !== undefined) {
            price = price - (price * group_discount.percentage / 100)
        }
        return <>
            {price.toFixed(2)}
        </>;
    };
    const renderTotal = (item, service, discount, group_discount) => {
        var price = 0;
        if (item.discount !== '') {
            price = service.price - (service.price * item.discount_amount / 100)
        } else {
            price = service.price;
        }
        if (group_discount !== undefined) {
            price = price - (price * group_discount.percentage / 100)
        }
        if (item.complement !== '') {
            price = price + item.complement_amount;
        }
        price = price * item.units
        return <>
            {price.toFixed(2)}
        </>;
        // service.price - (service.price * item.discount_amount / 100)   formatter.format(item.price * item.units)
    };
    const showCartItem = (item, index) => {
        var familiar = null;
        if (item.dest_user && String(item.dest_user).indexOf('familiar-') !== -1) {
            var porciones = item.dest_user.split('familiar-');
            familiar = familiars.find((element) => parseInt(element.id) === parseInt(porciones[1]))
        }
        var service = services.filter(obj => {return obj.id === item.service_id})[0]
        if (service.with_booking) {
            var facility = service.facility_id == item.facility_id ? service.facility : service.facility.facilities.filter(obj => {return obj.id === parseInt(item.facility_id)})[0]
        } else {
            var facility = null
        }

        var complement = service.complements.filter(obj => {return parseInt(obj.id) === parseInt(item.complement)})[0]
        var discount = service.discounts.filter(obj => {return parseInt(obj.id) === parseInt(item.discount)})[0]
        var group_discount = discounts.filter(obj => {return parseInt(obj.group_id) === parseInt(service.group_id)})[0]
        return <>
            <div className="user-review courses-curriculum">
                <div className="review-rating">
                    <span className="d-inline-block">{service.name}</span>
                </div>
                <ul>
                    {familiar ?
                        <>
                            <li className="" key={index + 'fam'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Familiar</span>
                                    <div className="courses-meta">
                                        <span className="duration">{familiar.name} {familiar.surname}</span>
                                    </div>
                                </div>
                            </li>
                        </>
                        :
                        ''
                    }
                    {facility !== null ?
                        <>
                            <li className="" key={index + '1'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Instalación</span>
                                    <div className="courses-meta">
                                        <span className="duration">{facility?.name}</span>
                                    </div>
                                </div>
                            </li>
                        </>
                        :
                        ''
                    }
                    {service.with_booking ?
                        <>
                            <li className="" key={index + 'reserva'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Reserva</span>
                                    <div className="courses-meta">
                                        <span className="duration">{item?.serviceDate} {item?.serviceHour}</span>
                                    </div>
                                </div>
                            </li>
                        </>
                        :
                        ''
                    }
                    {
                        item.specialDate !== '' ?
                            <>
                                <li className="" key={'zona1'}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="courses-name">Fecha</span>
                                        <div className="courses-meta">
                                            <span className="duration">
                                                {
                                                    format(new Date(service.facility.permanent_bookings.find((element) => parseInt(element.id) === parseInt(item.specialDate)).day), "dd/MM/yyyy", {locale: es}) + ` (${service.facility.permanent_bookings.find((element) => parseInt(element.id) === parseInt(item.specialDate)).start.split(':').slice(0, 2).join(':')}-${service.facility.permanent_bookings.find((element) => parseInt(element.id) === parseInt(item.specialDate)).end.split(':').slice(0, 2).join(':')})`

                                                }
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </>
                            :
                            ''

                    }
                    {
                        item.zone_id !== '' ?
                            <>
                                <li className="" key={'zona1'}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="courses-name">Zona</span>
                                        <div className="courses-meta">
                                            <span className="duration">{service.facility.zones.find((element) => parseInt(element.id) === parseInt(item.zone_id)).name}</span>
                                        </div>
                                    </div>
                                </li>
                            </>
                            :
                            ''

                    }
                    {
                        item.seats.length > 0 ?
                            showSeats(item)
                            :
                            ''
                    }
                    <li className="" key={index + '2'}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="courses-name">Cantidad</span>
                            <div className="courses-meta">
                                <span className="duration">{item.units}</span>
                            </div>
                        </div>
                    </li>
                    <li className="" key={index + '3'}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="courses-name">Precio</span>
                            <div className="courses-meta">
                                <span className="duration">{formatter.format(service.price)}</span>
                            </div>
                        </div>
                    </li>
                    {item.discount !== '' ?
                        <>
                            <li className="" key={index + '4'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Descuento aplicado: {discount.name}</span>
                                    <div className="courses-meta">
                                        <span className="duration">{discount.percentage} %</span>
                                    </div>
                                </div>
                            </li>

                        </>
                        :
                        ''
                    }
                    {service.group_id !== '' && group_discount !== undefined ?
                        <>
                            <li className="" key={index + '66'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Descuento por compra múltiple</span>
                                    <div className="courses-meta">
                                        <span className="duration">{group_discount.percentage.toFixed(2)} %</span>
                                    </div>
                                </div>
                            </li>

                        </>
                        :
                        ''
                    }
                    {(service.group_id !== '' && group_discount !== undefined) || item.discount !== '' ?
                        <>
                            <li className="" key={index + '5'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Precio con descuento</span>
                                    <div className="courses-meta">
                                        <span className="duration">{renderSubTotal(item, service, discount, group_discount)} €</span>
                                    </div>
                                </div>
                            </li>

                        </>
                        :
                        ''
                    }
                    {item.complement !== '' ?
                        <>
                            <li className="" key={index + '6'}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="courses-name">Complemento: {complement.name}</span>
                                    <div className="courses-meta">
                                        <span className="duration">{item.complement_amount} €</span>
                                    </div>
                                </div>
                            </li>

                        </>
                        :
                        ''
                    }

                    <li className="" key={index + '7'}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="courses-name">Total</span>
                            <div className="courses-meta">
                                <span className="duration">{renderTotal(item, service, discount, group_discount)} €</span>
                            </div>
                        </div>
                    </li>

                    {item.extra_fields.length !== 0 ?
                        <>
                            {item.extra_fields.map((field, extra_index) => (
                                <li className="" key={index + extra_index + '8'}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="courses-name">{service.fields?.find(sfield => sfield.label === field.name)?.name}</span>
                                        <div className="courses-meta">
                                            <span className="duration">{field.value}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </>
                        :
                        ''
                    }

                </ul>
                <div className="d-flex">

                    <div className="courses-meta">
                        <button className='delete-btn' onClick={deleteItem(item)} data-id={index}>

                            <i className='bx bx-trash'></i>

                            Eliminar<span></span>
                        </button>
                    </div>
                </div>
            </div>

        </>;

    };
    const cartOrder = () => {
        setError(null)
        setOrdering(true)

        api.post(`${process.env.API_URL}/cartOrder`, {cart: cart, payment_type: paymentMethod}).then(response => {
            localStorage.removeItem('cart');

            window.location.href = `${process.env.BACKEND_URL}/pay/${response.data.order.id}`
        })
            .catch(error => {
                setOrdering(false)
                switch (error.response?.status) {
                    case 401: // Not logged in
                    case 419: // Session expired

                        localStorage.removeItem('user')
                        window.dispatchEvent(new Event("removeUser"));
                        navigate('/signin')
                        break;

                    default:
                        setError(error.response.data.error)
                }
            })


    }
    const showSeats = (item) => {
        (async () => {
            try {
                var seats = await getSeats(item.zone_id, item.specialDate);

                return <>
                    <li className="" key={item.id + 'seats'}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="courses-name">Asientos</span>
                            <div className="courses-meta">
                                <span className="duration">
                                    {item.seats.map((seat, index) => (
                                        <>
                                            {seats.find((element) => parseInt(element.id) === parseInt(seat))?.name}
                                            {index < item.seats.length - 1 ? ', ' : ''}
                                        </>
                                    ))}
                                </span>
                            </div>
                        </div>
                    </li>
                </>
            } catch (error) {
                return <></>
            }
        })()

    }
    const deleteItem = (item) => {
        return () => {
            var cart = JSON.parse(localStorage.getItem('cart'))
            var index = cart.findIndex(obj => {return obj.id === item.id})
            cart.splice(index, 1)
            localStorage.setItem('cart', JSON.stringify(cart))
            if (item.order_id) {
                
                api.delete(`${process.env.API_URL}/orders/${item.order_id}`)
                    .then(response => {
                        window.location.reload()
                    })
                    .catch(error => {
                        // setError(error.response.data.error)
                        window.location.reload()
                    })

            } else {               
                window.location.reload()

            }
        }
    }
    return (
        <section className="courses-details-area pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="courses-details-desc">
                            <div className="courses-review-comments">
                                <h3>{cart ? cart.length + ' elementos' : ''} </h3>
                                {cart && cart.length > 0 ?
                                    <>
                                        {cart.map((item, index) => showCartItem(item, index))}
                                    </>
                                    :
                                    <p>La cesta está vacía</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        {showRelated ?

                            <div className='contact-sidebar'>
                                <div className='contact-sidebar-info cart'>
                                    <ul>
                                        <li>
                                            <span>Servicios relacionados:</span>
                                            {related.map((item, index) => (
                                                <Link to={`/service-details?slug=${item.slug}`}>
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            : null
                        }
                        {cart && cart.length !== 0 ?
                            <div className="courses-details-info">
                                <ul className="info">
                                    <li className="price" key="total">
                                        <div className="d-flex justify-content-between align-items-center"><span><i
                                            className="flaticon-price-tag"></i> Total</span> {total}€</div>
                                    </li>
                                    {total > 0 ?
                                        <>
                                            <li key='payMethod'>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span>Paga con</span>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <select className="form-select w-100" name="payment_type" value={paymentMethod} onChange={handleChange}>
                                                        <option value="card">Tarjeta</option>
                                                        {bizum === true ?
                                                            <>
                                                                <option value="bizum">Bizum</option>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                                <div className="d-flex justify-content-center mt-2 align-items-center payment-method">
                                                    {/* <img src={payment1} className="shadow" alt="payment-card" /> */}
                                                    <img src={payment2} className="shadow ms-1 payment-card" alt="VISA" />
                                                    <img src={payment4} className="shadow ms-1 payment-card" alt="MASTER CARD" />
                                                    {bizum === true ?
                                                        <>
                                                            <img src={payment5} className="shadow ms-1 payment-card-bizum" alt="BIZUM" />
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </li>
                                        </>
                                        :
                                        null
                                    }
                                </ul>

                                <div className="btn-box">
                                    <a aria-current="page" disabled={(cart && cart.length === 0) || ordering ? 'disabled' : ''} className={(cart && cart.length === 0) || ordering ? 'default-btn disabled' : 'default-btn'}
                                        onClick={cart && cart.length === 0 ? null : cartOrder} >
                                        {ordering ?
                                            <div className="spinner-border spinner-border-sm pr-5" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            :
                                            <i className="flaticon-shopping-basket"></i>
                                        }
                                        Finalizar compra
                                        <span></span>
                                    </a>
                                </div>


                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity='error' sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </section>

    )
}

export default CartContent